function appSettings() {
  return {
    urlMainnet: "https://tips.nearspace.info/",
    urlTestnet: "https://testnet.tips.nearspace.info/",
    appNme: "Tips",
    appDescription: "Sends tip in NEAR tokens direct in Telegram",
    github: "https://github.com/zavodil/tipbot",
  };
}

module.exports = appSettings
